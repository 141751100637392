import * as React from 'react';

import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';

import { card, cardWrapper, card_photo, card_nameTag, card_links,
     } from './team-member-card.module.scss';


const TeamMemberCard = ({data}) => {
    
    return (
        <div className={card}>
            <div className={cardWrapper}>
                <div className={card_photo}>
                    { 
                        data.memberImage !== null
                        ? <GatsbyImage alt={`${data.name} image`} image={getImage(data.memberImage)} />
                        : <StaticImage src='../../assets/images/icons/user-1.png' alt={`${data.name} image`} />
                    }
                </div>
                <div className={card_nameTag}>
                    <h4>{ data.name }</h4>
                    <h5>{ data.title }</h5>
                    { data.phoneNumber && 
                        <a href={`tel:${data.phoneNumber}`}>{ data.phoneNumber.slice(1) }</a>
                    }
                    <div className={card_links}>
                        { data.email &&
                            <a href={`mailto:${data.email}`} title={`Email ${data.name} `}>
                                <StaticImage src='../../assets/images/icons/mail-icon.png' alt="email icon" />
                            </a>
                        }
                        { data.linkedInProfile &&
                            <a href={ data.linkedInProfile } target="_blank" rel="noreferrer" title={`Contact ${data.name} on LinkedIn`}>
                                <StaticImage src='../../assets/images/icons/linkedin-icon.png' alt="linkedin icon" />
                            </a>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TeamMemberCard