import * as React from 'react';

import { graphql, useStaticQuery } from "gatsby";

import Layout from '../components/Layout/layout';
import PageHero from '../components/PageHero';
import ReferAFriendForm from '../components/ReferAFriendForm';
import { Seo } from '../components/Seo/seo';
import TeamSection from '../components/TeamSection';
import TextBlockSection from '../components/TextBlockSection';
import TextImageSection from '../components/TextImageSection';

const TeamPage = () => {


    const data = useStaticQuery(graphql`
    {
      allContentfulPageLayout(filter: {id: {eq: "b1c67ea2-7988-5c08-a501-c61ca41138e6"}} ){
        edges {
          node {
            name
            title
            content {
              ... on ContentfulPageHero {
                id
                name
                bannerImage {
                  gatsbyImageData(
                    width: 1440
                    height: 345
                    quality: 100
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
                bannerText {
                  raw
                }
                smallBannerImage {
                  gatsbyImageData(
                    width: 950
                    height: 345
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                  )
                }
              }
              ... on ContentfulTextBlock {
                id
                name
                title {
                  raw
                }
                body {
                  body
                }
                colorScheme
              }
              ... on ContentfulTeamMembersSection {
                id
                name
                sectionTitle {
                  raw
                }
                teamMembers {
                  id
                  name
                  title
                  phoneNumber
                  email
                  linkedInProfile
                  memberImage {
                    gatsbyImageData(width: 215, quality: 100, placeholder: BLURRED, height: 340)
                  }
                }
              }
              ... on ContentfulTextImageSection {
                id
                name
                title {
                  raw
                }
                bodyText {
                  bodyText
                }
                href
                textLeft
                sectionImage {
                  gatsbyImageData(width: 387, quality: 100, placeholder: BLURRED)
                }
              }
            }
            contactForm
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)

    const hero = data.allContentfulPageLayout.edges[0].node.content[0]
    const textBlock = data.allContentfulPageLayout.edges[0].node.content[1]
    const team = data.allContentfulPageLayout.edges[0].node.content[2]
    const textImage = data.allContentfulPageLayout.edges[0].node.content[3]
    const form = data.allContentfulLocation.edges[0].node.phoneNumber

  return (
    <Layout>
      <PageHero data={ hero } />
      <TextBlockSection data={ textBlock } />
      <TeamSection data={ team } />
      <TextImageSection data = { textImage } />
      <ReferAFriendForm data={ form } />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="Meet the TGL Team" 
    description="The TGL Med Staff is a team of professionals that are available at any time to answer your questions."
    pathname="/team"

  />

)

export default TeamPage