import * as React from 'react';

import { renderRichText } from 'gatsby-source-contentful/rich-text';

import TeamMemberCard from '../TeamMemberCard';

import { team, team_title, team_teamCards, team_memberCard } from './team-section.module.scss';

const TeamSection = ({data}) => {

    return (
        <section className={team}>
            <div>
                <div className={team_title}>
                    {renderRichText(data.sectionTitle)}
                </div>
                <div className={team_teamCards}>
                    {data.teamMembers.map((member) => 
                        <div key={member.id} className={team_memberCard}>
                            <TeamMemberCard data={member} />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default TeamSection